import { useEffect, useState } from "react";
import card from "./Card.module.css";
import { useNavigate } from "react-router-dom";
import productsOnDashboard from "../../Data/ProductsOnDashboard";
import { IsProductOnDashboard } from "../../Functions/IsProductOnDashboard";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";

export default function Card(props) {
  const [products, setProducts] = useState([]);
  const [image, setImage] = useState("");
  const [isProdOnDashboard, setProdOnDashboard] = useState();
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [cookies, setCookies, removeCookies] = useCookies();
  const { t, i18n } = useTranslation("global"); // Specify the namespace if needed

  useEffect(() => {
    fetchProducts();
  }, [props.produktet, productsOnDashboard]);

  function fetchProducts() {
    setProducts(props.produktet);
  }

  function handleImageClick(e) {
    window.scrollTo({
      top: 100,
  });
    let id = e.target.id;
    navigate(`/product/${id}`);
  }

  function handleAddToDashboard(e) {
    // Add your add to dashboard logic here
  }

  function isProductAddedToCard(prodId) {
    return IsProductOnDashboard(prodId);
  }

  return (
    <div
      className={`${card.containeri} d-flex container-fluid justify-content-center`}
      style={{}}
    >
      {products.map((prod, index) => (
        <div
          className={`card container-fluid ${card.karta}`}
          key={index}
          style={{
            width: "30%",
            minWidth: "350px",
            height: "60vh",
            margin: "20px",
            marginTop: "50px",
            padding: "0",
            borderRadius: "0",
            cursor: "pointer",
          }}
        >
          <div
            className={`card-header ${card.cardHeader}`}
            style={{ background: "white", padding: "0", height: "87%", borderRadius: "0px" }}
            onMouseOver={() => isProductAddedToCard(prod.ProductID)}
          >
            {prod.ProductImages && prod.ProductImages.split(",")[0] && (
              <img
                id={prod.ProductID}
                src={prod.ProductImages.split(",")[0]}
                alt={prod.ProductName}
                className="card-img-top"
                style={{ width: "100%", height: "100%", borderRadius: "0px" }}
                onClick={handleImageClick}
              />
            )}
          </div>
          <div
            className={`card-body ${card.cardBody}`}
            style={{ cursor: "pointer", display: cookies["role"] === "Administrator" ? "none" : "" }}
          >
            <span
              id={prod.ProductID}
              className={`${card.spani}`}
              onClick={handleAddToDashboard}
            >
              <span className={`${isProductAddedToCard(prod.ProductID) ? "" : "d-none"}`}>
                <i className={`bi bi-check-circle`} style={{ color: "green" }}></i>
              </span>
              <span>{t("card.neShporte")}</span>
            </span>
          </div>
          <div className="card-footer" style={{ height: "13%" }}>
            <div>
              <span>{prod.ProductName}</span>
            </div>
            <div>
              <span style={{ textDecoration: "line-through", marginRight: "10px" }}>
                {prod.offerPrice !== undefined && prod.offerPrice !== null
                  ? `${prod.offerPrice.toFixed(2)} `
                  : ""}
              </span>
              <span>
                {prod.sellingPrice !== undefined && prod.sellingPrice !== null
                  ? `${prod.sellingPrice.toFixed(2)} euro`
                  : ""}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
