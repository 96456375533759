import style from "./Store.module.css"
import image from "./dyqani.jpg"
import { useTranslation } from "react-i18next";
import i18next from "i18next";

export default function Store() {
    const { t, i18n } = useTranslation('global'); // Specify the namespace if needed


    return (
        <div className={`${style.main}`}>
            <div><span style={{ fontWeight: "700", fontFamily: "Antique", fontSize: "30px", paddingRight: "1px" }}>BioProd</span><span className={`${style.highlight}`}
            >{t("store.nga")}</span><div className={`${style.backgr}`}></div></div>
            <div>
                <div className={`${style.history}`}>
                    <div style={{  fontFamily: "Antique", fontSize: "18px", paddingTop: "15px"}} className={`${style.divText}`}>
                        <p>{t("store.p1")}</p>
                        <p>{t("store.p2")}</p>
                        <p>{t("store.p3")}</p>
                    </div>
                    <div className={` ${style.divImage}`}>
                        <img className={`${style.image}`} src={`${image}`}></img>
                    </div>
                </div>
            </div>
        </div>
    )
}