import { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
// import boyImage from "./boy.png";
import boyImage from "../../photos/background4.png" 

import style from "./UserAccount.module.css";

export default function UserAccount() {
    const [userDetails, setUserDetails] = useState();
    const [cookies, setCookies, removeCookies] = useCookies();
    const navigate = useNavigate();
    const email = cookies["email"];
    const username = cookies["username"];
    const role = cookies["role"];

    function handleLogOut() {
        removeCookies("token");
        removeCookies("role");
        navigate("/");
    }

    function handlePurchaseHistory() {
        navigate("/purchaseHistoryUser");
    }

    return (
        <div className="container-fluid">
            <div className={`${style.main}`}>
                <div className={`${style.divImage}`}>
                    <img src={(cookies["gender"] == "m") ? boyImage : boyImage} className={`${style.image}`}></img>
                </div>
                <div className={`row ${style.data}`} style={{ marginLeft: "5px", marginRight: "20px" }}>
                    <p className="col" style={{ textAlign: "center" }}>Username</p>
                    <p className="col" style={{ textAlign: "center", maxWidth: "150px" }}>{username}</p>
                </div>
                <div className={`row ${style.data}`} style={{ marginLeft: "5px", marginRight: "20px" }}>
                    <p className="col" style={{ textAlign: "center" }}>Email</p>
                    <p className="col" style={{ textAlign: "center", wordWrap: "break-word", maxWidth: "150px" }}>{email}</p>
                </div>
                {/* {role === 'Shopper' && (
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "10px", marginTop: "15px", marginBottom: "10px" }}>
                        <button style={{ width: "80%", background: "#3498a3", color: "white", borderColor: "#7a150f", borderWidth: "3px", borderStyle: "solid", borderRadius: "10px" }} onClick={handlePurchaseHistory}>Historia e blerjeve</button>
                    </div>
                )} */}
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "20px", marginTop: "15px", marginBottom: "10px" }}>
                    <button className={`${style.btn1}`} style={{ width: "90%" }} onClick={handleLogOut}>LogOut</button>
                </div>
            </div>
        </div >
    );
}
