import React, { useEffect, useState } from "react";
import { returnPaginationRange } from "./appUtils";
import { util } from "./utils";
import pagination from "./Pagination.module.css"

export default function Pagination(props) {
    const [array, setArray] = useState([]);

    useEffect(() => {
        addToArray();
    }, [props.page, props.totalPage]);

    function addToArray() {
        let arr = returnPaginationRange(props.totalPage, props.page, props.limit, props.siblings);
        setArray(arr);
    }

    return (
        <ul className={`pagination pagination-lg justify-content-center container-fluid ${pagination.main}`} >
            <li className={`page-item ${pagination.item}`}><span  className={`page-link`}  onClick={()=> props.onPageChange("L")}>L</span></li>
            {/* <li className="page-item"><span className="page-link"  onClick={()=> props.onPageChange("l")}>l</span></li> */}
            {array.map(value => {
                if (value === props.page) {
                    return (
                        <li key={value} className={`page-item ${pagination.item} active`}><span className={`page-link`} style={{backgroundColor:"#771923", border:"none", color:"white"}} onClick={()=> props.onPageChange(value)}>{value}</span></li>
                    );
                } else {
                    return (
                        <li key={value} className={`page-item ${pagination.item}`}><span className="page-link" onClick={()=> props.onPageChange(value)}>{value} </span></li>
                    );
                }
            })}
            {/* <li className="page-item"><span className="page-link" onClick={()=> props.onPageChange("r")}>r</span></li> */}
            <li className={`page-item ${pagination.item}`}><span className="page-link"  onClick={()=> props.onPageChange("R")}>R</span></li>
        </ul>
    );
}