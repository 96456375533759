import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import style from "./ProductCategorized.module.css"
import { prod, getLength } from "../Home/Produkte.js"
import axios from "axios"
import Card from "../../components/Card/Card"
import Pagination from "../../components/Paggination/Pagination"
import { Link } from "react-router-dom"
import { handleResizeWindow } from "../../Functions/HandleResizeWindow.js"
import { useCookies } from "react-cookie"
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import i18next from "i18next";
import { useLocation } from "react-router-dom"
import LoadingIndicator from 'react-loading-indicator';

export default function ProductCategorized() {
    const params = useParams()
    const location = useLocation()
    useEffect(() => {
    }, [location.pathname])

    const category = params.category
    const [loading, setLoading] = useState(true); // Add loading state
    const categoryFilter = params.categoryFilter
    const [cookies, setCookies, removeCookies] = useCookies()
    const { t, i18n } = useTranslation('global'); // Specify the namespace if needed

    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;
    const [allProductsPath, setAllProductsPath] = useState([])
    const [page, setPage] = useState(1);
    const [limit, setLimits] = useState(9);
    const [products, setProducts] = useState([])
    const totalPage = Math.ceil(getLength(products) / limit)
    function handlePageChange(value) {

        switch (value) {
            case "R": {
                window.scrollTo({
                    top: 100,
                    behavior: 'smooth',
                })
                if (page != totalPage)
                    setPage(page + 1);


            }
                break;
            case "L": {
                window.scrollTo({
                    top: 100,
                    behavior: 'smooth',
                })
                if (page != 1)
                    setPage(page - 1);

            }
                break;
            default:
                window.scrollTo({
                    top: 100,
                    behavior: 'smooth',
                })
                setPage(value);
        }

    }

    useEffect(() => {

        function handleResize() {
            setLimits(handleResizeWindow())

        }
        handleResize()

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        setLoading(true);
        if (categoryFilter == undefined) {
            axios.post(`${baseApiUrl}/specificGenderProducts`, {
                "gender": category

            }).then(res => {
                setProducts(res.data[0])
                setLoading(false);
            })
        }
        else {
            axios.post(`${baseApiUrl}/specificGenderProducts`, {
                "gender": category,
                "categoryFilters": [categoryFilter]

            }).then(res => {
                setProducts(res.data[0])
                setLoading(false);
            })
            setPage(1)
        }
    }, [category, categoryFilter])


    // if (loading) {
    //     return (
    //         <div className={style.loaderContainer}>
    //             <LoadingIndicator  />
    //             </div>
    //     );
    // }

    return (
        <div className={` ${style.main} container-fluid`}>
            <NavLink to="/productCategory/krema" className={`${style.categoryDescrioption} ${"/productCategory/krema" == location.pathname ? style.categoryDescrioptionActive : ""}`} style={{ display: category == "krema" ? "block" : "none" }}>
                <button className={style.categoryButton}>{t("navBar.krema")}</button>
            </NavLink>
            <NavLink to="/productCategory/floke" className={`${style.categoryDescrioption} ${"/productCategory/floke" == location.pathname ? style.categoryDescrioptionActive : ""}`} style={{ display: category == "floke" ? "block" : "none" }}>
                <button className={style.categoryButton}>{t("navBar.floke")}</button>
            </NavLink>
            <NavLink to="/productCategory/vajra" className={`${style.categoryDescrioption} ${"/productCategory/vajra" == location.pathname ? style.categoryDescrioptionActive : ""}`} style={{ display: category == "vajra" ? "block" : "none" }}>
                <button className={style.categoryButton}>{t("navBar.vajra")}</button>
            </NavLink>
            <NavLink to="/productCategory/parfume" className={`${style.categoryDescrioption} ${"/productCategory/parfume" == location.pathname ? style.categoryDescrioptionActive : ""}`} style={{ display: category == "parfume" ? "block" : "none" }}>
                <button className={style.categoryButton}>{t("navBar.parfume")}</button>
            </NavLink>
            <NavLink to="/productCategory/kapsula" className={`${style.categoryDescrioption} ${"/productCategory/kapsula" == location.pathname ? style.categoryDescrioptionActive : ""}`} style={{ display: category == "kapsula" ? "block" : "none" }}>
                <button className={style.categoryButton}>{t("navBar.kapsula")}</button>
            </NavLink>
            <NavLink to="/productCategory/mjalte" className={`${style.categoryDescrioption} ${"/productCategory/mjalte" == location.pathname ? style.categoryDescrioptionActive : ""}`} style={{ display: category == "mjalte" ? "block" : "none" }}>
                <button className={style.categoryButton}>{t("navBar.mjalte")}</button>
            </NavLink>
            <NavLink to="/productCategory/cajra" className={`${style.categoryDescrioption} ${"/productCategory/cajra" == location.pathname ? style.categoryDescrioptionActive : ""}`} style={{ display: category == "cajra" ? "block" : "none" }}>
                <button className={style.categoryButton}>{t("navBar.cajra")}</button>
            </NavLink>

            <hr className={`${style.hline}`}></hr>

            <div className={style.categoryType} style={{ display: category == "krema" ? "block" : "none" }}>
                <NavLink to={`/productCategory/${category}/fytyr`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.fytyr")}</button>
                </NavLink>
            </div>


            <div className={style.categoryType} style={{ display: category == "floke" ? "block" : "none" }}>
               
                <NavLink to={`/productCategory/${category}/shampo`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.shampo")}</button>
                </NavLink>
                <NavLink to={`/productCategory/${category}/thinjura`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.thinjura")}</button>
                </NavLink>
                <NavLink to={`/productCategory/${category}/bojra`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.bojra")}</button>
                </NavLink>
                <NavLink to={`/productCategory/${category}/sapun`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.sapun")}</button>
                </NavLink>
            </div>

            

            <div className={style.categoryType} style={{ display: category == "vajra" ? "block" : "none" }}>
                <NavLink to={`/productCategory/${category}/vendore`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.vendore")}</button>
                </NavLink>
                <NavLink to={`/productCategory/${category}/importuara`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.importuara")}</button>
                </NavLink>
            </div>

            <div className={style.categoryType} style={{ display: category == "parfume" ? "block" : "none" }}>
                <NavLink to={`/productCategory/${category}/meshkuj`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.meshkuj")}</button>
                </NavLink>
                <NavLink to={`/productCategory/${category}/femra`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.femra")}</button>
                </NavLink>
                <NavLink to={`/productCategory/${category}/dyGjinit`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.dyGjinit")}</button>
                </NavLink>
            </div>

            <div className={style.categoryType} style={{ display: category == "mjalte" ? "block" : "none" }}>
                <NavLink to={`/productCategory/${category}/shtimPeshe`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.shtimPeshe")}</button>
                </NavLink>
                <NavLink to={`/productCategory/${category}/humbjePeshe`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.humbjePeshe")}</button>
                </NavLink>
            </div>

            <div className={style.categoryType} style={{ display: category == "cajra" ? "block" : "none" }}>
                <NavLink to={`/productCategory/${category}/shtimPeshe`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.shtimPeshe")}</button>
                </NavLink>
                <NavLink to={`/productCategory/${category}/humbjePeshe`} className={({ isActive }) => isActive ? `${style.button} ${style.active}` : style.button}>
                    <button className={style.button}>{t("navBar.humbjePeshe")}</button>
                </NavLink>
            </div>


            <hr className={`${style.hline}`} style={{ display: (category == "kapsula") ? "none" : "" }}></hr>
            {
                (loading) ? <LoadingIndicator /> :
                    <div>
                        <Card produktet={prod(page, limit, products)}></Card>
                        <Pagination totalPage={totalPage} page={page} limit={limit} siblings={1} onPageChange={handlePageChange}></Pagination>
                    </div>
            }
        </div>
    )
}