import React, { useEffect, useState } from 'react';
import './Timeline.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faShoppingCart, faXmark } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
// import ConfirmDialog from './ConfirmDialog';
import ConfirmDialog from "./confirmDialog"
import { useTranslation } from "react-i18next";
import i18next from "i18next";
const Timeline = () => {
    const [purchases, setPurchases] = useState([]);
    const [loading, setLoading] = useState(false);
    const [cookies] = useCookies(['userId']);
    const { t, i18n } = useTranslation('global'); // Specify the namespace if needed
    const [showDialog, setShowDialog] = useState(false);
    const [selectedPurchaseId, setSelectedPurchaseId] = useState(null);
    const [selectedPurchaseEmail, setSelectedPurchaseEmail] = useState('');
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

    const id = cookies.userId;

    const markAsCanceled = async (id) => {
        try {
            const token = Cookies.get('token');
            if (!token) {
                throw new Error('No token found in cookies');
            }

            await axios.get(`${baseApiUrl}/markCanceled/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            });

            // Update the status of the specific purchase locally
            setPurchases(prevPurchases =>
                prevPurchases.map(purchase =>
                    purchase.PurchaseID === id ? { ...purchase, Status: 'canceled' } : purchase
                )
            );
        } catch (error) {
            console.error('There was an error!', error);
        }
    };

    const sendCancelEmail = async () => {
        const token = Cookies.get('token');

        try {
            const userEmail = Cookies.get('email');
            await axios.get(`${baseApiUrl}/sendCancelEmail/${userEmail}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                },
                timeout: 10000 // Set timeout to 10 seconds
            }
            );
        } catch (error) {
            console.error('There was an error sending the cancel email!', error);
        }
    };

    const handleCancelClick = (id, email) => {
        setSelectedPurchaseId(id);
        setSelectedPurchaseEmail(email);
        setShowDialog(true);
    };

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleDialogConfirm = () => {
        if (selectedPurchaseId) {
            markAsCanceled(selectedPurchaseId);
            sendCancelEmail(selectedPurchaseEmail);
        }
        setShowDialog(false);
    };

    const fetchHistory = async () => {
        setLoading(true);
        try {
            const token = Cookies.get('token');
            if (!token) {
                throw new Error('No token found in cookies');
            }

            const response = await axios.get(`${baseApiUrl}/buyHistoryUser/${id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token
                }
            });

            if (response.status !== 200) {
                throw new Error('Network response was not ok');
            }

            // Extract the purchases data from the response
            const purchasesData = response.data[0];

            // Set the purchases state with the fetched data
            setPurchases(purchasesData || []); // Ensure it's always an array
        } catch (error) {
            console.error('There was an error!', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchHistory();
    }, []);

    return (
        <div className="timeline">
            {loading ? (
                <p>Loading...</p>
            ) : purchases.length > 0 ? (
                purchases.map((purchase, index) => {
                    const date = new Date(purchase.Timestamp);
                    const day = date.getDate();
                    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
                    const year = date.getFullYear();
                    const hours = String(date.getHours()).padStart(2, '0');
                    const minutes = String(date.getMinutes()).padStart(2, '0');
                    const imageUrls = purchase.URLs.split(',');
                    const price = (purchase.Price * purchase.Quantity).toFixed(2);
                    const status = purchase.Status;
                    return (
                        <div key={index} className={`event ${status === 'in progress' ? 'in-progress' : status}`}>
                            <div className={`circle ${status === 'in progress' ? 'in-progress' : status}`}></div>
                            <div className="date">
                                <span>{`${day}/${month}/${year}`}</span>
                                <br />
                                <span>{`${hours}:${minutes}`}</span>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faCalendarAlt} />
                                </div>
                            </div>
                            <div className='content'>
                                <div className={`content ${status === 'in progress' ? 'in-progress' : status}`}>
                                    <div className="status">{status === 'finished' ? 'Blerja e kompletuar' : status === 'in progress' ? 'Blerja është bërë me sukses ' : 'Blerja është anuluar'}</div>
                                </div>
                                <div className='secondRow'>
                                    <div className="field">Sasia: {purchase.Quantity}</div>

                                    <div className="image-container">
                                        {imageUrls.map((url, idx) => (
                                            <img key={idx} src={url} alt={`Product ${idx}`} className="product-image" />
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="rightColumn">
                                <div className="price">
                                    <div className="price-text">{price}€</div>
                                </div>

                                <button className="action-button-cancel" onClick={() => handleCancelClick(purchase.PurchaseID, purchase.Email)}>
                                    <FontAwesomeIcon icon={faXmark} />
                                </button>
                            </div>
                        </div>
                    );
                })
            ) : (
                <div className="no-purchases">
                    <p>{t("timeline.p1")}<br />{t("timeline.p2")}</p>
                    <FontAwesomeIcon icon={faShoppingCart} size="3x" />
                </div>
            )}

            <ConfirmDialog
                show={showDialog}
                onClose={handleDialogClose}
                onConfirm={handleDialogConfirm}
                message="A je i sigurt që dëshiron ta anulosh porosinë?"
            />
        </div>
    );
};

export default Timeline;
