import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import '../node_modules/flag-icon-css/css/flag-icons.min.css'
import $ from "jquery"
import { BrowserRouter } from 'react-router-dom';
import Register from './pages/Authentication$Profile/Register';
import Login from './pages/Authentication$Profile/Login';
import global_en from "./translation/en/global.json"
import global_sq from "./translation/sq/global.json"
import { I18nextProvider } from 'react-i18next';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';



i18next
  .use(initReactI18next)
  .init({
    interpolation: { escapeValue: false }, // React already escapes values
    lng: 'sq', // Default language
    resources: {
      en: { global: global_en },
      sq: { global: global_sq }
    }
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <I18nextProvider i18n={i18next}>
    <App />

    </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
